import React from 'react'
import Grid from '@material-ui/core/Grid'
// import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button'


export default function CustomerPayment(props){
    // let history = useHistory();
    
    const [invoice, setInvoice] = React.useState('')

    const getInvoiceId = async () => {
        const queryString = await window.location.search
        const urlParams = await new URLSearchParams(queryString)
        const id = await urlParams.get('invoice-id')
        return id
    }

    const getInvoice = async () => {
        const invoiceId = await getInvoiceId()
        const apiCall = await fetch(`${window.bcaApiUrl}/invoices/${invoiceId}`, {
            method: 'GET',
        })
        const invoice = await apiCall.json()
        setInvoice(invoice)
    }

    React.useEffect(() => {
        getInvoice()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const paymentForm = new window.SqPaymentForm({
        // Initialize the payment form elements

        //TODO: Replace with your sandbox application ID
        applicationId: window.applicationId,
        inputClass: 'sq-input',
        autoBuild: false,
        // Customize the CSS for SqPaymentForm iframe elements
        inputStyles: [{
            fontSize: '16px',
            lineHeight: '24px',
            padding: '16px',
            placeholderColor: '#a0a0a0',
            backgroundColor: 'transparent',
        }],

        // Initialize the credit card placeholders
        cardNumber: {
            elementId: 'sq-card-number',
            placeholder: 'Card Number'
        },
        cvv: {
            elementId: 'sq-cvv',
            placeholder: 'CVV'
        },
        expirationDate: {
            elementId: 'sq-expiration-date',
            placeholder: 'MM/YY'
        },
        postalCode: {
            elementId: 'sq-postal-code',
            placeholder: 'Postal'
        },
        // SqPaymentForm callback functions
        callbacks: {
            paymentFormLoaded: function(){
                console.log('form loadeddddd')
            },
            /*
            * callback function: cardNonceResponseReceived
            * Triggered when: SqPaymentForm completes a card nonce request
            */
            cardNonceResponseReceived: function (errors, nonce, cardData) {
            if (errors) {
                // Log errors from nonce generation to the browser developer console.
                console.error('Encountered errors:');
                errors.forEach(function (error) {
                    console.error('  ' + error.message);
                });
                alert('Encountered errors, check browser developer console for more details');
                return;
            }
            fetch(`${window.bcaApiUrl}/process-payment`, {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    body: {
                        nonce: nonce,
                        amount: (parseInt(invoice.amount) + (parseInt(invoice.amount) * .03)) * 100,
                        env: window.env
                    }
                })
              })
              .catch(err => {
                alert('Network error: ' + err);
              })
              .then(response => {
                if (!response.ok) {
                  return response.json().then(errorInfo => Promise.reject(errorInfo)); //UPDATE HERE
                }
                return response.json(); //UPDATE HERE
              })
              .then(data => {
                console.log(data); //UPDATE HERE
                alert('Payment complete successfully!\nWe are excited to see you soon!');
              })
              .catch(err => {
                console.error(err);
                alert('Payment failed to complete!\nTry a new card or contact Capt. David Lowry');
              });

            }
        }
      });
      //TODO: paste code from step 1.1.4
      //TODO: paste code from step 1.1.5
    
    paymentForm.build();

    function onGetCardNonce(e) {
        console.log('weee')
        // Don't submit the form until SqPaymentForm returns with a nonce
        e.preventDefault();
        // Request a nonce from the SqPaymentForm object
         paymentForm.requestCardNonce();
      }

    return(
        <div>
        <Grid container justify="center" alignItems="center" style={{padding:'20px'}} >
            <Grid item xs={12} md={8} lg={5} wrap="no-warp">
                <h1>Hi, {invoice.name}</h1>
                <Button onClick={() => window.location.href = 'http://domain.com'}>TEST</Button>
                <p>Thanks for booking with Baffin Cabins. Please fill out your card information and complete payment to confirm your trip with us. You will receive a confirmation email once payment goes thorough. Tight Lines!</p>
                <br/>
                <h3 style={{marginBottom:'10px'}}>Trip Dates: {invoice.arivalDate} - {invoice.departureDate}</h3>
                <h3 style={{margin:'0px'}}>Trip Fee: ${invoice.amount}</h3>
                <h3 style={{marginTop:'10px', marginBottom:'0px'}}>Processing Fee: ${invoice.amount * .03} </h3>
                <span style={{fontSize:'12px'}}>*Fee charged to process card payments.</span>
                <h2 ><u>Total Amount:</u> ${parseInt(invoice.amount) + (parseInt(invoice.amount) * .03)}</h2>
            </Grid>
        </Grid>
        <Grid container justify="center" alignItems="center" style={{padding:'20px'}} >
            <br/>
            <br/>
            <Grid item xs={12} md={8} lg={5} >
                <div id="form-container">
                    <div id="sq-card-number" ></div>
                    <div className="third" id="sq-expiration-date" ></div>
                    <div className="third" id="sq-cvv" ></div>
                    <div className="third" id="sq-postal-code" ></div>
                    <button id="sq-creditcard" className="button-credit-card" onClick={e => onGetCardNonce(e)} >Complete Payment</button>
                </div>
            </Grid>
        </Grid>
        </div>
    )
}